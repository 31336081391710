@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: whitesmoke;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

  /* Track */
::-webkit-scrollbar-track {
    background: rgb(88, 139, 139, 0.3);
}

  /* Handle */
::-webkit-scrollbar-thumb {
    background: #588b8b;
    border-radius: 10px;
}

  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(88, 139, 139, 0.8);
}
